<template>
    <div>
        <a-spin tip="Chargement..."  :spinning="processing">
            <a-row type="flex" justify="center">
                <a-col :span="24">
                    <a-table
                            bordered
                            v-if="dataSource.length"
                            :dataSource="dataSource"
                            :columns="columns"
                            :pagination="false"
                            :rowKey="r => r.id"
                    >

                        <template slot="row_index" slot-scope="text, record, index">
                            <p>{{ index + 1 }}</p>
                        </template>
                        <template slot="user_name" slot-scope="text, record, index">
                            <p>
                                {{ get(record ,'user.nom_fr') }} {{ get(record,'user.prenom_fr') }}
                            </p>
                        </template>
                        <template slot="reference" slot-scope="text, record">
                            <span v-bind:class="{ black: isNewPaiement(record) }">
                              <a-badge v-if="isNewPaiement(record)" status="processing"/>
                              {{ record.code }}
                            </span>
                        </template>
                        <template slot="exercice_du" slot-scope="text, record, index">
                            <p>{{ get(record, "exercice.annee") | year }}</p>
                        </template>

                        <template slot="piece_jointe" slot-scope="text, record, index">
                            <!-- _________________________VISUALIZE -->
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Visualiser</span>
                                </template>

                                <a-icon
                                        type="search"
                                        @click="previewDocument(record.file_link)"
                                        class="action_icons"
                                        v-if="record.file_name"
                                />
                            </a-tooltip>
                        </template>
                        <template slot="date_paiement" slot-scope="text, record, index">
                            <strong>{{ record.dt_encaissement | date }}</strong>
                        </template>
                        <template slot="type" slot-scope="text, record, index">
                            <strong>{{ get( record , 'type.nom_fr' )}} </strong>
                        </template>
                        <template slot="etat" slot-scope="text, record, index">
                            <a-icon
                                    type="check-circle"
                                    v-if="isVisiteValide(record)"
                                    theme="twoTone"
                                    class="action_icons"
                                    twoToneColor="#52c41a"
                            />
                                                              <a-icon
                                                                      v-else
                                                                      type="close-circle"
                                                                      class="action_icons"
                                                                      theme="twoTone"
                                                                      twoToneColor="#eb2f96"
                                                              />


                        </template>
                        <template slot="user" slot-scope="text, record, index">
                            {{record.nom_fr }} {{record.prenom_fr }}
                        </template>
                          <template slot="dt_visite" slot-scope="text, record, index">
                            {{record.dt_reel_visite | date }} 
                        </template>
                        <template slot="dt_demande" slot-scope="text, record, index">
                            {{date(record.dt_demande)}}
                        </template>
                        <template
                                class="table-operation"
                                slot="operation"
                                slot-scope="text, record"
                        >
                            <a-tooltip placement="top">
                                <template slot="title">
                                    <span>Edition</span>
                                </template>
                                <a-icon
                                        type="edit"
                                        v-on:click="onEdit(record.id)"
                                        class="margin_right action_icons"
                                        theme="twoTone"
                                        twoToneColor="#52c41a"
                                />
                            </a-tooltip>
                            <a-tooltip placement="top" v-if="isVisiteValide(record)">
                                <template slot="title">
                                    <span>Imprimer</span>
                                </template>
                                <a-icon
                                        @click="printDocument(record)"
                                        class="action_icons"
                                        theme="twoTone"
                                        type="printer"
                                />
                            </a-tooltip>
                            <a-popconfirm
                                    title="Etes-vous sûr de vouloir supprimer cette ligne ?"
                                    @confirm="() => onDelete(record.id)"
                            >
                                <a-tooltip placement="top">
                                    <template slot="title">
                                        <span>Suppression</span>
                                    </template>
                                    <a-icon
                                            type="delete"
                                            class="action_icons"
                                            theme="twoTone"
                                            twoToneColor="#eb2f96"
                                    />
                                </a-tooltip>
                            </a-popconfirm>
                            <a-tooltip placement="top" v-if="get(record,'paiement.file_name')">
                                <template slot="title">
                                    <span>Visualiser</span>
                                </template>
                                <a-icon
                                        type="search"
                                        @click="previewDocument(record.file_link)"
                                        class="action_icons"
                                />
                            </a-tooltip>
                              <!-- <a-tooltip placement="top" v-if="get(record,'reglement.montant')">
                                <template slot="title">
                                    <span>Payer</span>
                                </template>
                                <a-icon
                                        type="dollar"
                                        @click="paye(get(record,'reglement.montant'))"
                                        class="action_icons"
                                />
                            </a-tooltip>  -->
                        </template>
                        <template slot="upload" slot-scope="text, record">
                            <Uploader :action="getUploadURL(record.id)" :record="record" v-if="isAdmin && record.paiement"
                                      :bearer="getAuthorizationToken()"
                                      @upload_success="$emit('upload_success', $event)"
                                      @delete_file="$emit('delete_file', $event)"/>
                        </template>
                    </a-table>
                    <EmptyResult v-else/>
                </a-col>
            </a-row>
        </a-spin>
    </div>
</template>
<script>
    import EmptyResult from "@/components/common/EmptyResult";
    // import EtatDemandesOuverturesIndicator from "@/components/common/EtatDemandesOuverturesIndicator";
    import Uploader from "@/components/common/Uploader";
    import {mapState, mapActions} from "vuex";

    let userColumns = [
        {
            title: "N°",
            scopedSlots: {customRender: "row_index"},
        },
        {
            title: "Type visite",
            dataIndex: "type",
            scopedSlots: {customRender: "type"},
        },
        {
            title: "Dt. visite",
            dataIndex: "dt_reel_visite",
            scopedSlots: {customRender: "dt_visite"},

        },
        {
            title: "Etat",
            scopedSlots: {customRender: "etat"},
        },
    ];
    let adminColumns = [
        {
            title: "N°",
            scopedSlots: {customRender: "row_index"},
        },
        {
            title: "Type visite",
            dataIndex: "type",
            scopedSlots: {customRender: "type"},
        },
        {
            title: "Dt. visite",
            dataIndex: "dt_reel_visite",
            scopedSlots: {customRender: "dt_visite"},
        },
        {
            title: "Etat",
            scopedSlots: {customRender: "etat"},
        },
        {
            title: "Actions",
            scopedSlots: {customRender: "operation"},
        },
        {
            title: "Piéce jointe",
            scopedSlots: {customRender: "upload"},
        },

    ];


    export default {
        name: "ListeVisites",
        components: {
            EmptyResult,
            // EtatDemandesOuverturesIndicator,
            Uploader
        },
        data() {
            return {
                columns: this.isAdmin ? adminColumns : userColumns,
            };
        },
        props: {
            dataSource: Array,
            selectedUser: {type :Object, default : () => {}},
            processing: {
                type: Boolean,
                default: () => false
            },
            isAdmin: {
                type: Boolean,
                default: () => false
            },
        },
        computed: {
            ...mapState({base_url: (state) => state.auth.base_url,}),
        },
        methods: {
            getUploadURL(id) {
                return `${this.base_url}/admin/upload-visite-documents/${id}`;

            },
            printDocument(visite) {
                this.startProcessing()
                this.callPdfGenerator({
                    id: this.get(visite, "user.id"),
                    type: this.get(visite, "type.rapport.type"),
                    organisation: this.get(visite, "user.organisation_id"),
                    id_demande: visite.demande_id,
                })
                    .catch(() => this.$_throwAnError())
                    .finally(() => this.stopProcessing());
            },
            paye(montant){
               // let link = `https://aderial.com.dz/pr?id=2&key=SfEbp3jdxaMafdaI7dqqsm_Ad&montant=${montant}&description=Frais de la visite.`
                //window.open(`${link}`, "_blank")
            },
            isVisiteValide(visite) {
                return visite.dt_reel_visite ;
            },
            startProcessing() {
                this.processing = true
            },
            stopProcessing() {
                this.processing = false
            },
            onDelete(id) {
                this.$emit('delete', id)
            },
            onEdit(id) {
                this.$emit('edit', id)
            },
            goToVisite(id) {
                this.$emit('visite', id)
            },
            previewDocument(link) {
                window.open(link);
            },
            ...mapActions({
                callPdfGenerator: "callPdfGenerator",
              }),
        },
    };
</script>
<style scoped>
    .action_icons {
        margin: 4px;
        font-size: large;
    }

    .margin_right {
        margin-right: 5px;
    }
</style>